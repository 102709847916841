<template>
    <el-col>
        <el-divider content-position="left">
            <b>
                {{ item.category }}
                <span v-if="isNew || isUpdate" class="ml-2 badge badge-success rounded">
                    {{ isNew ? 'New': (isUpdate ? 'Update': '')}}
                </span>
            </b>
        </el-divider>
        <div class="box-inside">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <h5 class="flex" >Phòng quản lý: <b class="text-danger">{{ item.room_management }}</b> </h5>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <h5 class="flex" v-if="link" >
                    Đường dẫn : <el-link :href="link" :underline="false" type="primary" target="_blank" icon="el-icon-link">Xem chi tiết</el-link>
                </h5>
            </el-col>
            <el-col class="mt-2 mb-2" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <i class="float-right size-1">Cập nhật lần cuối: {{ $moment(item.updated_at).format('HH:m:s DD-MM-YYYY') }}</i>
            </el-col>
            <el-table
                    v-if="dataTable.length > 0"
                    stripe
                    :data="dataTable"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="name_item"
                        label="Nội dung">
                </el-table-column>
                <el-table-column label="Đường dẫn">
                    <!--eslint-disable-->
                    <template slot-scope="scope">
                        <el-link v-if="scope.row.link_item" :href="scope.row.link_item" :underline="false" type="primary" target="_blank" icon="el-icon-link">Xem chi tiết</el-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-divider v-if="dataTable.length > 0" class="mt-5"></el-divider>
    </el-col>
</template>

<script>
    // import lodash from 'lodash-es';
    // import deepdash from 'deepdash-es';
    // const _ = deepdash(lodash);
    export default {
        name: "system-item",
        props: {
            item: Object
        },
        data() {
            return {
                isNew : false,
                isUpdate : false,
                dataTable: [],
                link: null
            }
        },
        methods:{

        },
        mounted() {
            if (this.item.isCreate) {
                this.isNew = true
            } else {
                if (this.item.isUpdate) {
                    this.isUpdate = true
                }
            }
            if (this.item && this.item.items) {
                this.dataTable = JSON.parse(this.item.items)
            }

            this.link = this.item.link
        }
    }
</script>
