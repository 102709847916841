<template>
    <div class="panel" v-bind:class="{
                'panel-collapsed panel-fullscreen' : isFullScreen,
                'panel-collapsed': isCollapsed,
            }">
        <div class="panel-hdr">
            <h2 class="cursor-pointer" @click="isCollapsed = !isCollapsed" >
                {{ text }}
                <span v-if="isNew" class="ml-2 badge badge-warning rounded">{{ numNew }} New</span>
                <span v-if="isUpdate" class="ml-2 badge badge-success rounded">{{ numUpdate }} Update</span>
            </h2>
            <div class="panel-toolbar">
                <i v-if="!isFullScreen" class="text-size-16 fal cursor-pointer" :class="{
                    'fa-plus-circle text-success': !isCollapsed,
                    'fa-minus-circle text-danger': isCollapsed }"
                   @click="isCollapsed = !isCollapsed"></i>
                <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0" :class="{'mr-3': isFullScreen}" data-action="panel-fullscreen" data-toggle="tooltip"
                        data-offset="0,10" data-original-title="Fullscreen"
                        @click="isFullScreen = !isFullScreen">
                    <i class="fal" :class="{'fa-times': isFullScreen, 'fa-expand': !isFullScreen}"></i>
                </button>
            </div>
        </div>
        <div class="panel-container collapse" ref="inside" :class="{ 'show': isCollapsed }">
            <div class="panel-content">
                <div class="demo-v-spacing">
                    <el-empty v-if="data.length === 0" description="Chưa có dữ liệu nào!"></el-empty>
                    <el-row v-else :gutter="10">
                        <system-item :item="item" v-for="(item, index) in data" :key="index" ></system-item>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SystemItem from "@/views/pages/information/system-item";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    export default {
        name: "system-panel",
        components: {
            SystemItem
        },
        props: {
            data: Array,
            text: String
        },
        data() {
            return {
                isFullScreen: false,
                isCollapsed: false,
                isNew: false,
                isUpdate: false,
                numNew: 0,
                numUpdate: 0,
            }
        },
        methods:{

        },
        mounted() {
            _.each(this.data, (item) => {
                if (!this.isNew && item.isCreate) {
                    this.isNew = true;
                    this.numNew += 1;
                }
                if (!this.isNew && item.isCreate) {
                    this.isUpdate = true;
                    this.numUpdate += 1;
                }
            })
        }
    }
</script>
