<template>
    <div class="row">
        <div class="col-md-12 col-xl-12">
            <!--Thông tin nội bộ-->
            <system-panel :data="data.dataInside" text="Thông tin nội bộ"></system-panel>

            <!--Hệ thống báo cáo-->
            <system-panel :data="data.dataReport" text="Hệ thống báo cáo"></system-panel>

            <!--Vận hành doanh nghiệp-->
            <system-panel :data="data.dataOperate" text="Vận hành doanh nghiệp"></system-panel>
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import {INFORMATION_SYSTEM} from "@/core/services/store/service.module";
    import SystemPanel from "@/views/pages/information/system-panel";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    export default {
        name: "information-system",
        components: {
            SystemPanel
        },
        data() {
            return {
                data: {
                    dataInside: [],
                    dataReport: [],
                    dataOperate: [],
                }
            }
        },
        methods:{
            queryServer() {
                this.$store.dispatch(INFORMATION_SYSTEM)
                    .then((response) => {
                        if (response.status === 422) {
                            this.loading = false;
                            this.$message({type: 'warning', message: `${response.message}`, showClose: true});
                        } else {
                            _.each(response.data, (item) => {
                                let updated = this.$moment(item.updated_at).add(6, 'days').format('YYYY-MM-DD');
                                let created = this.$moment(item.created_at).add(6, 'days').format('YYYY-MM-DD');
                                let current = this.$moment().format('YYYY-MM-DD');
                                item.isCreate = false;
                                item.isUpdate = false;
                                if (current <= created) {
                                    item.isCreate = true
                                }
                                if (current <= updated) {
                                    item.isUpdate = true
                                }
                                if (item.type_system_information === 1) {
                                    this.data.dataInside.push(item)
                                }
                                if (item.type_system_information === 2) {
                                    this.data.dataReport.push(item)
                                }
                                if (item.type_system_information === 3) {
                                    this.data.dataOperate.push(item)
                                }
                            })
                        }
                    })
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Thông tin", icon: 'far fa-info-square'},
                {title: "Hệ thống thông tin", icon: 'far fa-info-square'}
            ]);
            this.queryServer();
        }
    }
</script>
